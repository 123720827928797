import React from "react";
// Sections

/**
 * Theme imported
 */

import  "../style/index.css";

import './../style/hdcassets/hdcassetv1/wp-content/plugins/ele-custom-skin/assets/css/ecs-styleaeb9.css'
import './../style/hdcassets/hdcassetv1/wp-content/plugins/wp-flexible-map/css/styles02d8.css'
import './../style/hdcassets/hdcassetv1/wp-content/themes/mtn-cdi-210/style3c94.css'
import './../style/hdcassets/hdcassetv1/wp-content/themes/mtn-cdi-210/style8217.css'
import './../style/hdcassets/hdcassetv1/wp-content/themes/mtn-theme-210/css/bootstrap.min8217.css'
import './../style/hdcassets/hdcassetv1/wp-content/themes/mtn-theme-210/css/icones8217.css'
import './../style/hdcassets/hdcassetv1/wp-content/themes/mtn-theme-210/css/materialdesignicons.min8217.css'
import './../style/hdcassets/hdcassetv1/wp-content/themes/mtn-theme-210/css/owl.carousel.min8217.css'
import './../style/hdcassets/hdcassetv1/wp-content/themes/mtn-theme-210/prism/prism8217.css'
import './../style/hdcassets/hdcassetv1/wp-content/themes/mtn-theme-210/theme-style.min8217.css'
import './../style/hdcassets/custom.css'



import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
import Header from "../components/Sections/Header";
import { Routes, Route } from "react-router-dom"
import {Home} from "./Home";
import {NotFound} from "./NotFound";
import {Faq} from "./Faq";
export default function Landing() {
    return (
        <>
            <Routes>
                <Route path="/" element={ <Home/>  }  />
            </Routes>
        </>
    );
}


