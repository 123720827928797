import React from "react"

export function MenuFooter({title , hasSybmenu , listeMenu}) {

    return(

        <li itemScope="itemscope" itemType="https://www.schema.org/SiteNavigationElement"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children accordion-dropdown depth-0 menu-item-1634 hdc-accordion__panel nav-item">
            <div className="hdc-accordion__title">
                <div className="hdc-accordion__toggle hdc-accordion--toggle">
                    <span className=" hdc-accordion__toggle__bar"></span>
                    <span className="hdc-accordion__toggle__bar"></span>
                </div>
                <a title="Particuliers" data-hover="dropdown"
                   aria-haspopup="true" aria-expanded="false" className="dropdown-toggle nav-link"
                   id="menu-item-dropdown-1634">{title}</a></div>
            {hasSybmenu ?
                <ul className="hdc-accordion__content accordion-dropdown" aria-labelledby="menu-item-dropdown-1634"
                    role="menu">
                    {listeMenu && listeMenu.map( (item) => {
                        return (
                            <li key={item.value} itemScope="itemscope" itemType="https://www.schema.org/SiteNavigationElement"
                                className="menu-item menu-item-type-post_type menu-item-object-page item-has-no-children depth-1 menu-item-1629 hdc-accordion__item">
                                <div className="p-l"><a title="Nos offres"
                                                        href={item.menu}
                                                        className="dropdown-item depth-1">{item.value}</a></div>
                            </li>
                        );
                    })}

                </ul>

            : null}

        </li>

    )
}