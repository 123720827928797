import Reat from "react"
import Header from "../components/Sections/Header";
import React from "react";
import {OurModules} from "../components/Sections/OurModules";
import Blog from "../components/Sections/Blog";
import Pricings from "../components/Sections/Pricings";
import WhyUs from "../components/Sections/WhyUs";
import FaqTopicAndResponse from "../components/Elements/FaqTopicAndResponse";
import {Helmet} from "react-helmet";



export function TermeCondition(props) {
    const questionsAndResponse = [
        {title:"Web Scraping & API" , questionsAndResponses: [{ quiz : "How scalable is this service?" , response : "Scrapestack is a REST API service built on top of  apilayer cloud infrastructure, making it highly scalable and capable   of handling anything from thousands of API requests per month all the way to millions per day. Powered by a system that scales up and down when needed, the API provides the highest possible level of speed for any API request at any stage."}] }
    ]
    return(
        <>
            <div id="content" className="site-content">"
                <div id="primary" className="content-area">

                    <main id="main" className="site-main">
                        <Header
                            title={"Terms and conditions "}
                            description={"."}
                            h={200}
                        />
                        <section>
                            <section className="faq full">


                            </section>
                        </section>
                    </main>
                </div>
            </div>
        </>
    );
}