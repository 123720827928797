import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from "@mui/material";
import {getRoot} from "../../common/helpers";
import { useTranslation } from 'react-i18next';



export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {


    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
      <>
        <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
        <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px"  , background:'#000' } : { height: "60px"  ,  background:'#000'}}>
          <NavInner className="container flexSpaceCenter">
            <a href={getRoot()} smooth={true} style={{width:"200px"}}>
              <LogoIcon />
            </a>
            <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
              <BurgerIcon />
            </BurderWrapper>
            <UlWrapper className="">
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" , color:"#e97d69" }} to="modules" spy={true} smooth={true} offset={-80}>
                Our modules
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" , color:"#e97d69"  }} to="usecase" spy={true} smooth={true} offset={-80}>
                  Use case
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" , color:"#e97d69" }} to="pricing" spy={true} smooth={true} offset={-80}>
                  Pricing
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" , color:"#e97d69"  }} to="whyus" spy={true} smooth={true} offset={-80}>
                  About HDC ?
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" ,color:"#e97d69" }} to="blog" spy={true} smooth={true} offset={-80}>
                  Blog
                </Link>
              </li>

              <li className="semiBold font15 pointer">
                <a href={getRoot()+'faq'} style={{ padding: "10px 30px 10px 0" , color: "#e97d69" }} style={{ padding: "10px 15px" , color:"#e97d69" }}>
                  FAQ
                </a>
              </li>

              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" , color:"#e97d69"  }} to="" spy={true} smooth={true} offset={-80}>
                  Server status
                </Link>
              </li>
            </UlWrapper>
            <UlWrapperRight className="flexNullCenter">
              <li className="semiBold font15 pointer">
                <a  style={{ padding: "10px 30px 10px 0" , color: "#e97d69" }}>
                  Log in
                </a>
              </li>
              <li className="semiBold font15 pointer flexCenter">
                <a href={getRoot()+'new-account'} className="radius8 " style={{ padding: "10px 15px"   , color:"#fff"}}>
                  Sign up
                </a>
              </li>
            </UlWrapperRight>
          </NavInner>
        </Wrapper>
      </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


