import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";
import {CurveRverse} from "../CurveReverse";
import {MenuFooter} from "../MenuFooter";
import {InfoFooter} from "../InfoFooter";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <>
      <CurveRverse/>
      <footer class="site-footer">

        <div class="hdc-footer hdc-curve hdc-curve--concave-top">
          <ul id="hdc-footer__accordion" className="hdc-accordion hdc-footer__accordion">
            <MenuFooter hasSybmenu={true} title={"Company"}
                        listeMenu={[
                          {menu:"" , value: "Team"}  ,
                          {menu:"" , value: "Features"}  ,
                          {menu:"" , value: "Use cases"}  ,
                          {menu:"" , value: "Datasets"}  ,
                          {menu:"" , value: "Choose NoCode"}  ,
                          {menu:"" , value: "Blog"}  ,
                          {menu:"" , value: "Request for a new template"}  ,
                          {menu:"" , value: "Request for your workflow creation"}  ,
                          {menu:"" , value: "Support"}  ,
                          {menu:"" , value: "Server status"}  ,
                          {menu:"" , value: "FAQ"}  ,
                          {menu:"" , value: "Contact"}  ,
                        ]}
            />
             <MenuFooter hasSybmenu={true} title={"Legal"}
                        listeMenu={[
                          {menu:"" , value: "Privacy & GDPR"}  ,
                          {menu:"" , value: "About web scraping"}  ,
                        
                        ]}
            />
             
             <MenuFooter hasSybmenu={true} title={"Compare Platforms"}
                        listeMenu={[
                          {menu:"" , value: "HDC VS ScrapingBee"}  ,
                          {menu:"" , value: "HDC VS Bright Data "}  ,
                          {menu:"" , value: "HDC VS Crawlera "}  ,
                          {menu:"" , value: "HDC VS Smartproxy"}  ,
                          {menu:"" , value: "HDC VS Zyte"}  ,
                          {menu:"" , value: "HDC VS Oxylabs"}  ,
                          {menu:"" , value: "HDC VS Stabler"}  ,
                        
                        ]}
            />
             
H            <MenuFooter hasSybmenu={false} title={"HDC Scrapping plateforme"}

            />
            <InfoFooter />
          </ul>

        </div>

  </footer>
    </>
  );
}
