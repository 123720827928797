import Reat from "react"
import Header from "../components/Sections/Header";
import React , {useState}from "react";
import {OurModules} from "../components/Sections/OurModules";
import Blog from "../components/Sections/Blog";
import Pricings from "../components/Sections/Pricings";
import WhyUs from "../components/Sections/WhyUs";
import LogoIcon from "../assets/svg/Logo";
import Logo from "./../assets/img/customcolor_logo_transparent_background.png"
import { useForm } from "react-hook-form";


export function Login(props) {

    const [user , setUser ] = useState({});
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);

    return(
        <>
            <div id="content" className="site-content" style={{paddingBottom:"70px"}}>
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <Header
                            title={"Login"}
                            description={""}
                            h={100}
                        />

                        <section  >
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col col-xl-10">
                                    <form  onSubmit={handleSubmit(onSubmit)}>
                                    <div className="" style={{borderRadius: "1rem"}}>
                                        <div className="row g-0">
                                            <div className="col-md-6 col-lg-5 d-none d-md-block">
                                                <img
                                                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                                                    alt="login form" className="img-fluid"
                                                    style={{borderRadius: "1rem 0 0 1rem"}}/>
                                            </div>
                                            <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                                <div className="card-body   text-black">

                                                        <div className="d-flex align-items-center " style={{height: "200px"}}>
                                                            <img src={Logo}  alt="session-logo" className="img-fluid" width="100%" height="40" />
                                                        </div>

                                                        <h5 className="fw-normal mb-3 mt-3 pb-3"
                                                            style={{letterSpacing: '1px'}}>Sign into your account</h5>

                                                        <div className="form-outline mb-4">
                                                            <input type="email" id="form2Example17"
                                                                   value={user.email}
                                                                    onChange={(e) => setUser(prevState => ({...prevState, email : e.target.value}))}
                                                                   className="form-control form-control-lg" {...register("emailUser" , { required: true })}/>
                                                            <label className="form-label themecolor" htmlFor="form2Example17">{errors.emailUser ? "* Ce champs est réquis" : "Email address"}</label>
                                                        </div>

                                                        <div className="form-outline mb-4">
                                                            <input type="password" id="form2Example27"
                                                                   onChange={(e) => setUser(prevState => ({...prevState, password : e.target.value}))}
                                                                   value={user.password}
                                                                   className="form-control form-control-lg" {...register("passwordUser", { required: true })}/>
                                                            <label className="form-label themecolor"
                                                                   htmlFor="form2Example27">{errors.emailUser ? "* Ce champs est réquis" : "Password"}</label>
                                                        </div>

                                                        <div className="pt-1 mb-4">
                                                            <button className="btn  btn-lg btn-block " style={{backgroundColor:"#e97d69"}}
                                                                    type="submit">Login
                                                            </button>
                                                        </div>

                                                        <a className="small themecolor" href="#!">Forgot password?</a>
                                                        <p className="mb-5 pb-lg-2" style={{ color: "#393f81"}}>Don't have
                                                            an account? <a href="#!"
                                                                           style={{color: "#000"}}>Register here</a></p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </>
    );
}