import React from "react"
import {getRoot} from "../../common/helpers";

export function OurModules() {


    return(
       <>
           <div className="container" id="modules">
               <div className="row">
                   <div className="col-12 hdc--title">

                       <div className="hdc-vertical-spacer" style={{height: '20px'}}></div>

                       <div className="hdc-title ">
                           <div className="hdc-title--icon">
                               <i className="hdc-icon hdc-icon-iot text-c-theme"></i></div>
                           <h4 className="hdc-title--heading">Our modules</h4>

                           <button type="default"
                                   className="hdc-fab-btn--mini  hdc-fab-btn--outlined-yellow"
                           >
                               <i className="mdi mdi-chevron-right"></i>
                           </button>

                       </div>

                   </div>
               </div>
           </div>
           <div className="owl-carousel owl-carousel--link-card owl-loaded" >
               <div className="owl-stage-outer">
                   <div className="owl-stage container " >

                       <div className="row">
                           <div className="col-md-4">
                               <div className="owl-item active" >
                                   <div className="item">
                                       <div  className="hdc-card hdc-card--shadow hdc-card--image hdc-card--link  hdc-card--cursor imageillustration">
                                           <div className="hdc-card__content">
                                               <div className="hdc-tag hdc-tag--theme">
                                                   Authentification
                                               </div>
                                               <h3 className="hdc-card__header hdc-h2">
                                                   See &amp; information </h3>
                                               <a id="" type="default"
                                                  href={getRoot()+'modules/1'}
                                                       className="hdc-btn  hdc-btn--text-right-icon hdc-btn--skin-theme ">
                                                   <span className="btn-text " >show more</span>
                                                   <i className="mdi mdi-18px mdi-chevron-right"></i>
                                               </a>

                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>

                       </div>

                   </div>
               </div>

           </div>
           <div className="hdc-vertical-spacer" style={{height: "20px"}}></div>
       </>
    );
}