import {Route, Routes} from "react-router-dom";
import Landing from "../screens/Landing";
import {Faq} from "../screens/Faq";
import {TermeCondition} from "../screens/TermeCondition";
import {PrivacyPolicy} from "../screens/PrivacyPolicy";
import {Login} from "../screens/Login";
import {Register} from "../screens/Register";
import {ChoosePlan} from "../screens/ChoosePlan";
import {Modules} from "../screens/Modules";
import {Dashboad} from "../screens/app/Dashboad";


export  const routes = [
    {path: "/" , component : Landing} ,
    {path: "/faq" , component : Faq} ,
    {path: "/hdc-terms-condition" , component : TermeCondition} ,
    {path: "/hdc-privacy-policy" , component : PrivacyPolicy} ,
    {path: "/login" , component : Login} ,
    {path: "/signup/register/:plan" , component : Register} ,
    {path: "/new-account" , component : ChoosePlan} ,
    {path: "/modules/:id" , component : Modules} ,

]

export const routeDashboad = [
    {path: "/dashboad" , component : Dashboad} ,
]