import Reat from "react"
import Header from "../components/Sections/Header";
import React from "react";
import {OurModules} from "../components/Sections/OurModules";
import Blog from "../components/Sections/Blog";
import Pricings from "../components/Sections/Pricings";
import WhyUs from "../components/Sections/WhyUs";



export function ChoosePlan(props) {

    return(
        <>
            <div id="content" className="site-content" style={{paddingBottom:"70px"}}>
                <div id="primary" className="content-area">

                    <main id="main" className="site-main">
                        <Header
                            title={"Choose your plan"}
                            description={""}
                            h={100}
                        />
                        <section  style={{marginTop:"-100px"}}>
                                <div className="container py-5 h-100">
                                    <Pricings/>
                                </div>
                            </section>
                    </main>
                </div>
            </div>


        </>
    );
}