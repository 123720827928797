import React , {useEffect} from "react"
import './../../style/pricing.css'

import styled from "styled-components";
import "./../../data/pricings.json" ;
import {getRoot} from "../../common/helpers";

export default function () {

    let pricings = require('./../../data/pricings.json') ;

    useEffect(( ) => {
        console.log(pricings)
    }, [])


    return(
        <>
            <Wrapper id="pricing"  >
                <section className="pricing ">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 hdc--title">
                                <div className="hdc-vertical-spacer" style={{height: '20px'}}></div>

                                <div className="hdc-title ">
                                    <div className="hdc-title--icon">
                                        <i className="hdc-icon hdc-icon-momo-pay text-c-theme"></i></div>
                                    <h4 className="hdc-title--heading">Pricing</h4>

                                    <button type="default"
                                            className="hdc-fab-btn--mini  hdc-fab-btn--outlined-yellow"
                                    >
                                        <i className="mdi mdi-chevron-right"></i>
                                    </button>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <ul className="subscriptions">

                            <li className="free">

                                <div className="main_container">

                                    <div className="mc_header">
                                        <heading>Free</heading>
                                    </div>

                                    <p className="short_description">Free forever, <br/>no credit card required.</p>

                                    <div className="price">

                                        <div className="monthly_data">
                                            <span>$0</span>
                                            <small className="frequency">per month</small>
                                            <small className="yearly_price">&nbsp;</small>
                                        </div>

                                        <div className="yearly_data">
                                            <span>$0</span>
                                            <small className="frequency">per month</small>
                                            <small className="yearly_price">&nbsp;</small>
                                        </div>

                                    </div>

                                    <a className="signup_link" href={getRoot()+"pricing/1"}
                                       data-href-monthly="/signup/free" data-href-yearly=""
                                       title="Sign up for the Free Plan">Sign Up</a>

                                </div>

                                <div className="features_container">

                                    <ul>
                                        <li className="feature_api_requests">100 Requests</li>
                                        <li className="feature_support">Limited Support</li>
                                        <li className="feature_sproxy">Standard Proxies</li>
                                        <li className="feature_empty">&nbsp;</li>
                                        <li className="feature_empty">&nbsp;</li>
                                        <li className="feature_empty">&nbsp;</li>
                                        <li className="feature_empty">&nbsp;</li>
                                        <li className="feature_empty">&nbsp;</li>
                                        <li className="feature_empty">&nbsp;</li>
                                    </ul>

                                </div>

                            </li>

                            <li className="standard">

                                <div className="main_container">

                                    <div className="mc_header">
                                        <heading>Basic</heading>
                                    </div>

                                    <p className="short_description">Starter level - access to premium features and
                                        up to 250.000 monthly requests.</p>

                                    <div className="price">

                                        <div className="monthly_data">
                                            <span>$19.99</span>
                                            <small className="frequency">per month</small>
                                            <small className="yearly_price">or $15.99 if billed yearly</small>
                                        </div>

                                        <div className="yearly_data">
                                            <span>$15.99</span>
                                            <small className="frequency">per month</small>
                                            <small className="yearly_price">billed yearly</small>
                                        </div>

                                    </div>

                                    <a className="signup_link" href={getRoot()+"pricing/2"}
                                       data-href-monthly="/signup/basic" data-href-yearly="/signup/basic/yearly"
                                       title="Sign up for the Basic Plan">Sign Up</a>

                                </div>

                                <div className="features_container">

                                    <ul>
                                        <li className="feature_api_requests">200.000 Requests</li>
                                        <li className="feature_support">Unlimited Support</li>
                                        <li className="feature_sproxy">Standard Proxies</li>
                                        <li className="feature_https">HTTPS Encryption</li>
                                        <li className="feature_concurrent">Concurrent Requests</li>
                                        <li className="feature_js">JavaScript Rendering</li>
                                        <li className="feature_geotargeting">100+ Geolocations</li>
                                        <li className="feature_empty">&nbsp;</li>
                                        <li className="feature_empty">&nbsp;</li>
                                    </ul>

                                </div>

                            </li>

                            <li className="standard highlighted" >

                                <div className="main_container">

                                    <div className="mc_header">
                                        <heading>Professional</heading>
                                    </div>

                                    <p className="short_description">Most popular - access to premium proxies and up
                                        to 1 million monthly requests.</p>

                                    <div className="price">

                                        <div className="monthly_data">
                                            <span>$79.99</span>
                                            <small className="frequency">per month</small>
                                            <small className="yearly_price">or $63.99 if billed yearly</small>
                                        </div>

                                        <div className="yearly_data">
                                            <span>$63.99</span>
                                            <small className="frequency">per month</small>
                                            <small className="yearly_price">billed yearly</small>
                                        </div>

                                    </div>

                                    <a className="signup_link" href={getRoot()+"pricing/3"}

                                       title="Sign up for the Professional Plan">Sign Up</a>

                                </div>

                                <div className="features_container">

                                    <ul>
                                        <li className="feature_api_requests">1.000.000 Requests</li>
                                        <li className="feature_support">Unlimited Support</li>
                                        <li className="feature_sproxy">Standard Proxies</li>
                                        <li className="feature_https">HTTPS Encryption</li>
                                        <li className="feature_concurrent">Concurrent Requests</li>
                                        <li className="feature_js">JavaScript Rendering</li>
                                        <li className="feature_geotargeting">100+ Geolocations</li>
                                        <li className="feature_pproxy">Premium Proxies</li>
                                        <li className="feature_empty">&nbsp;</li>
                                    </ul>

                                </div>

                            </li>

                            <li className="business_pro">

                                <div className="main_container">

                                    <div className="mc_header">
                                        <heading>Business</heading>
                                    </div>

                                    <p className="short_description">Business level - everything we have and up to 3
                                        million monthly requests.</p>

                                    <div className="price">

                                        <div className="monthly_data">
                                            <span>$199.99</span>
                                            <small className="frequency">per month</small>
                                            <small className="yearly_price">or $159.99 if billed yearly</small>
                                        </div>

                                        <div className="yearly_data">
                                            <span>$159.99</span>
                                            <small className="frequency">per month</small>
                                            <small className="yearly_price">billed yearly</small>
                                        </div>

                                    </div>

                                    <a className="signup_link" href={getRoot()+"pricing/4"}


                                       title="Sign up for the Business Plan">Sign Up</a>

                                </div>

                                <div className="features_container">

                                    <ul>
                                        <li className="feature_api_requests">3.000.000 Requests</li>
                                        <li className="feature_support">Premium Support</li>
                                        <li className="feature_sproxy">Standard Proxies</li>
                                        <li className="feature_https">HTTPS Encryption</li>
                                        <li className="feature_concurrent">Concurrent Requests</li>
                                        <li className="feature_js">JavaScript Rendering</li>
                                        <li className="feature_geotargeting">100+ Geolocations</li>
                                        <li className="feature_pproxy">Premium Proxies</li>
                                        <li className="feature_empty">&nbsp;</li>
                                    </ul>

                                </div>

                            </li>

                            <li className="enterprise">

                                <div className="main_container">

                                    <div className="mc_header">
                                        <heading>Enterprise</heading>
                                    </div>

                                    <p className="short_description">Looking for more?<br/> Contact us for a quote.
                                    </p>

                                    <div className="price">

                                        <div>
                                            <span>Custom Pricing</span>
                                            <small className="yearly_price">Tailored to your needs</small>
                                        </div>

                                    </div>

                                    <a className="signup_link" href={getRoot()+"pricing/5"}
                                       title="Get a tailored solution">Contact Us</a>

                                </div>

                                <div className="features_container">

                                    <ul>
                                        <li className="feature_api_requests">Volume Requests</li>
                                        <li className="feature_dedsupport">Dedicated Support</li>
                                        <li className="feature_sproxy">Standard Proxies</li>
                                        <li className="feature_https">HTTPS Encryption</li>
                                        <li className="feature_concurrent">Concurrent Requests</li>
                                        <li className="feature_js">JavaScript Rendering</li>
                                        <li className="feature_geotargeting">100+ Geolocations</li>
                                        <li className="feature_pproxy">Premium Proxies</li>
                                        <li className="feature_custom">Custom Solutions</li>
                                    </ul>

                                </div>

                            </li>

                        </ul>
                    </div>

                </section>
            </Wrapper>
            <div className="hdc-vertical-spacer" style={{height: "20px"}}></div>
        </>
    );
}
const Wrapper = styled.section`
  width: 100%;

`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




