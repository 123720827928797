import React from "react"
import {getRoot} from "../common/helpers";

export function InfoFooter() {

    return(
        <li className="hdc-footer__item">
            <div id="footer-section-widget-area" className="footer-section-widget-area widget-area"
                 role="complementary">

                <div className="justify-evenly social-icons-container hdc-footer__desktop-left">
                    <div className="social-icon justify-item">
                        <a href="" target="_blank"> <i
                            className="mdi mdi-facebook"></i> </a>
                    </div>

                    <div className="social-icon justify-item">
                        <a href="" target="_blank">
                            <i className="mdi mdi-twitter"></i>
                        </a>
                    </div>

                    <div className="social-icon justify-item">
                        <a href="" target="_blank">
                            <i className="mdi mdi-linkedin"></i>
                        </a>
                    </div>

                    <div className="social-icon justify-item">
                        <a href=""
                           target="_blank"> <i className="mdi mdi-youtube"></i> </a>
                    </div>

                    <div className="social-icon justify-item">
                        <a href="https://www.instagram.com/hdcci/" target="_blank">
                            <i className="mdi mdi-instagram"></i>
                        </a>
                    </div>
                </div>

                <div className="hdc-footer__desktop-left clearfix">
                    <div className="copyright-links hdc-text-copyright">© 2022 hdc Côte d&#039;Ivoire</div>
                    <div className="copyright-links hdc-text-copyright">
                        <a href={getRoot()+"hdc-terms-condition"}>Terms and conditions</a>
                        <a href={getRoot()+"hdc-privacy-policy"}>Privacy policy</a>
                    </div>
                </div>
            </div>
        </li>
    )
}