import React , {useEffect} from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import {getRoot} from "../../common/helpers";


export default function Header({title , h , description}) {

  const customHeader = () => {

      if(title ==  undefined ) {
          return(
            <>
                <div className="hdc-banner hdc-header-page" >

                    <div className="hdc-banner__bg">
                        <div className="hdc-banner__content">
                            <div className="container">
                                <Wrapper id="home" className="container flexSpaceCenter">
                                    <LeftSide className="flexCenter">
                                        <div>

                                            <HeaderP className="font13 semiBold">
                                                <h2>Automate your Web Scraping Workflow in just 5 minutes</h2>
                                                <p> Start collecting your data at scale by creating your workflow in few minutes and choose your serving method including most popular cloud providers, databases and API.Choose your scheduling, deploy your workflow and we will monitor and maintain it for you. </p>
                                                <p>No skills are required to build your workflow. With HDC Bot, think your workflow as your human experience by using our predefined modules. We also detect selectors for you automatically.</p>
                                                <a className="hdc-btn  hdc-btn--text-right-icon hdc-btn--skin-theme" href={"/"}>
                                                Start your free trial
                                                </a>
                                                &nbsp;
                                                &nbsp;
                                                <a className={"hdc-btn  hdc-btn--text-right-icon hdc-btn--skin-theme"} href={getRoot()+"/new-account"}>
                                                View demo 🎬<i className={"mdi mdi-open"}></i>
                                            </a>
                                            </HeaderP>
                                          
                                        </div>
                                    </LeftSide>
                                    <RightSide>
                                      
                                      
                                    </RightSide>
                                </Wrapper>
                            </div>
                        </div>
                    </div>

                    <div className="hdc-curve">
                        <div className="hdc-curve--concave-bottom">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                 x="0px" y="0px" height="25px" viewBox="0 0 500 25"
                                 style={{enableBackground: "new 0 0 500 25"}} xmlSpace="preserve" preserveAspectRatio="none"
                                 className="hdc-curve__concave hdc-curve__curve-mobile">
                                <g className="hdc-svg-fill--white">
                                    <path d="M0,25h250C166.7,25,83.3,16.7,0,0V25z"/>
                                    <path d="M250,25h250V0C416.7,16.7,333.3,25,250,25z"/>
                                </g>
                            </svg>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                 x="0px" y="0px" height="25px" viewBox="0 0 1366 25" xmlSpace="preserve"
                                 preserveAspectRatio="none" className="hdc-curve__concave hdc-curve__curve-desktop">
                                <path d="M683.1,23.9C386.6,23.9,129.2,14.1,0,0v25h1366V0C1236.7,14.1,979.4,23.9,683.1,23.9z"
                                      className="hdc-svg-fill--white" style={{zIndex: 2}}/>
                            </svg>
                        </div>
                    </div>
                </div>
            </>
          );
      }else if(h !== undefined) {

          return <>
              <div className="hdc-banner hdc-header-page" style={{height: "150px"}} >

                  <div className="hdc-banner__bg">
                      <div className="hdc-banner__content">
                          <div className="container">
                              <Wrapper id="home" className="container flexSpaceCenter">
                                  <LeftSide className="flexCenter">
                                      <div>

                                          <HeaderP className="font13 semiBold">
                                              <h2>{title}</h2>
                                              <p> {description}</p>
                                          </HeaderP>


                                          <a className="hdc-btn  hdc-btn--text-right-icon hdc-btn--skin-theme" href={getRoot()}>
                                              Back Home
                                          </a>

                                      </div>
                                  </LeftSide>
                                  <RightSide>

                                  </RightSide>
                              </Wrapper>
                          </div>
                      </div>
                  </div>

                  <div className="hdc-curve">
                      <div className="hdc-curve--concave-bottom">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                               x="0px" y="0px" height="25px" viewBox="0 0 500 25"
                               style={{enableBackground: "new 0 0 500 25"}} xmlSpace="preserve" preserveAspectRatio="none"
                               className="hdc-curve__concave hdc-curve__curve-mobile">
                              <g className="hdc-svg-fill--white">
                                  <path d="M0,25h250C166.7,25,83.3,16.7,0,0V25z"/>
                                  <path d="M250,25h250V0C416.7,16.7,333.3,25,250,25z"/>
                              </g>
                          </svg>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                               x="0px" y="0px" height="25px" viewBox="0 0 1366 25" xmlSpace="preserve"
                               preserveAspectRatio="none" className="hdc-curve__concave hdc-curve__curve-desktop">
                              <path d="M683.1,23.9C386.6,23.9,129.2,14.1,0,0v25h1366V0C1236.7,14.1,979.4,23.9,683.1,23.9z"
                                    className="hdc-svg-fill--white" style={{zIndex: 2}}/>
                          </svg>
                      </div>
                  </div>
              </div>
              </>
      }else {
          return <>
              <div className="hdc-banner-custom hdc-header-page darkBg text-white text-center" >

                  <div className="hdc-banner__bg">
                      <div className="hdc-banner__content  mt-5">
                          <div className="container mt-2">
                              <h2>{title}</h2>
                              <p>{description}</p>
                          </div>
                      </div>
                  </div>


              </div>
          </>
      }
  }

  const getHeith = () => {
      if(h == null || h == undefined) {
          return
      }

      return {heigth: `${h}px`}
  }

  useEffect(() => {
      console.log(getHeith())
  }, [])

  return (
    <>
        <header className="page-header">
            {customHeader()}

        </header>
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;



