import React from "react"


export function CurveRverse() {

    return(
        <div className="hdc-separator">
            <div className="hdc-curve">

                <div className="hdc-curve--convex-top">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                         x="0px" y="0px" height="25px" viewBox="0 0 500 25" style={{enableBackground:'new 0 0 500 25'}}
                         xmlSpace="preserve" preserveAspectRatio="none"
                         className="hdc-curve__convex hdc-curve__curve-mobile">
                        <path d="M0,0c166.7,33.3,333.3,33.3,500,0H0z" className="hdc-svg-fill--white"/>
                    </svg>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                         x="0px" y="0px" height="25px" viewBox="0 0 1366 25" xmlSpace="preserve"
                         preserveAspectRatio="none" className="hdc-curve__convex hdc-curve__curve-desktop">
                        <path d="M1366,0c-129.2,14.8-386.6,25-682.9,25C386.6,25,129.2,14.8,0,0H1366z"
                              className="hdc-svg-fill--white"/>
                    </svg>
                </div>

                <div className="hdc-curve--concave-bottom">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                         x="0px" y="0px" height="25px" viewBox="0 0 500 25" style={{enableBackground:'new 0 0 500 25'}}
                         xmlSpace="preserve" preserveAspectRatio="none"
                         className="hdc-curve__concave hdc-curve__curve-mobile">
                        <g className="hdc-svg-fill--black">
                            <path d="M0,25h250C166.7,25,83.3,16.7,0,0V25z"/>
                            <path d="M250,25h250V0C416.7,16.7,333.3,25,250,25z"/>
                        </g>
                    </svg>

                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                         x="0px" y="0px" height="25px" viewBox="0 0 1366 25" xmlSpace="preserve"
                         preserveAspectRatio="none" className="hdc-curve__concave hdc-curve__curve-desktop">
                        <path d="M683.1,23.9C386.6,23.9,129.2,14.1,0,0v25h1366V0C1236.7,14.1,979.4,23.9,683.1,23.9z"
                              className="hdc-svg-fill--black" style={{ zIndex: "2"}}/>
                    </svg>
                </div>
            </div>
        </div>
    );
}