import Recat from "react"
import "./../style/blogs-css.css"
import Header from "../components/Sections/Header";

export default  () => {


    return (
        <div id="content" className="site-content">
            <div id="primary" className="content-area">

                <main id="main" className="site-main">

                    <section className="api_documentation mt-3 mb-5" >

                        <div className="container mt-2">
                            <div className="row align-items-start">
                                <div className="col-lg-8 m-15px-tb">
                                    <article className="article">
                                        <div className=" article-card" data-cat="">
                                            <div className="hdc-card hdc-card--shadow hdc-card--image hdc-card--filter  hdc-card--cursor" data-year=""
                                                 data-cat=""
                                            >

                                                <div className="hdc-card__content">
                                                    <div className="hdc-tag hdc-tag--white"></div>
                                                    <div className="hdc-btn hdc-btn--text-right-icon hdc-btn--outlined">
                                                        <div className="article-title">
                                                            <h6><a href="#">Lifestyle</a></h6>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div className="article-content">
                                            <p>Aenean eleifend ante maecenas pulvinar montes lorem et pede dis dolor pretium donec
                                                dictum. Vici consequat justo enim. Venenatis eget adipiscing luctus lorem.
                                                Adipiscing veni amet luctus enim sem libero tellus viverra venenatis aliquam.
                                                Commodo natoque quam pulvinar elit.</p>
                                            <p>Eget aenean tellus venenatis. Donec odio tempus. Felis arcu pretium metus nullam quam
                                                aenean sociis quis sem neque vici libero. Venenatis nullam fringilla pretium magnis
                                                aliquam nunc vulputate integer augue ultricies cras. Eget viverra feugiat cras ut.
                                                Sit natoque montes tempus ligula eget vitae pede rhoncus maecenas consectetuer
                                                commodo condimentum aenean.</p>
                                            <h4>What are my payment options?</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            <blockquote>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                                                <p className="blockquote-footer">Someone famous in <cite title="Source Title">Dick
                                                    Grayson</cite></p>
                                            </blockquote>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                                deserunt mollit anim id est laborum.</p>
                                        </div>
                                        <div className="nav tag-cloud">
                                            <a href="#">Design</a>
                                            <a href="#">Development</a>
                                            <a href="#">Travel</a>
                                            <a href="#">Web Design</a>
                                            <a href="#">Marketing</a>
                                            <a href="#">Research</a>
                                            <a href="#">Managment</a>
                                        </div>
                                    </article>

                                </div>
                                <div className="col-lg-4 m-15px-tb blog-aside">


                                    <div className="widget widget-latest-post">
                                        <div className="widget-title">
                                            <h3>Latest Post</h3>
                                        </div>
                                        <div className="widget-body">
                                            <div className="latest-post-aside media">
                                                <div className="lpa-left media-body">
                                                    <div className="lpa-title">
                                                        <h5><a href="#">Prevent 75% of visitors from google analytics</a></h5>
                                                    </div>
                                                    <div className="lpa-meta">
                                                        <a className="name" href="#">
                                                            Rachel Roth
                                                        </a>
                                                        <a className="date" href="#">
                                                            26 FEB 2020
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="lpa-right">
                                                    <a href="#">
                                                        <img src="https://via.placeholder.com/400x200/FFB6C1/000000" title=""
                                                             alt=""/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="latest-post-aside media">
                                                <div className="lpa-left media-body">
                                                    <div className="lpa-title">
                                                        <h5><a href="#">Prevent 75% of visitors from google analytics</a></h5>
                                                    </div>
                                                    <div className="lpa-meta">
                                                        <a className="name" href="#">
                                                            Rachel Roth
                                                        </a>
                                                        <a className="date" href="#">
                                                            26 FEB 2020
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="lpa-right">
                                                    <a href="#">
                                                        <img src="https://via.placeholder.com/400x200/FFB6C1/000000" title=""
                                                             alt=""/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="latest-post-aside media">
                                                <div className="lpa-left media-body">
                                                    <div className="lpa-title">
                                                        <h5><a href="#">Prevent 75% of visitors from google analytics</a></h5>
                                                    </div>
                                                    <div className="lpa-meta">
                                                        <a className="name" href="#">
                                                            Rachel Roth
                                                        </a>
                                                        <a className="date" href="#">
                                                            26 FEB 2020
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="lpa-right">
                                                    <a href="#">
                                                        <img src="https://via.placeholder.com/400x200/FFB6C1/000000" title=""
                                                             alt=""/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget widget-tags">
                                        <div className="widget-title">
                                            <h3>Latest Tags</h3>
                                        </div>
                                        <div className="widget-body">
                                            <div className="nav tag-cloud">
                                                <a href="#">Design</a>
                                                <a href="#">Development</a>
                                                <a href="#">Travel</a>
                                                <a href="#">Web Design</a>
                                                <a href="#">Marketing</a>
                                                <a href="#">Research</a>
                                                <a href="#">Managment</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                </main>
            </div>
        </div>
    );
}