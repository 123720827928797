import React from "react" ;
import QuestionFaqResponse from "./QuestionFaqResponse";

export default function (props) {



    return(
        <div className="topic">
            {props.questionsAndResponse.map( (item1) => {

                return (
                   <div>
                       <h3>{item1.title}</h3>
                       <ul>
                            { item1.questionsAndResponses.map( (it, key) =>  {
                                return <QuestionFaqResponse key={key} question={it.quiz} response={it.response} hide={false}/>
                            })}
                       </ul>
                   </div>
                )
             })}

        </div>
    )
}