import React ,{useState , useEffect} from "react" ;

export default function (props) {

    const { hide , question , response } = props
    const [show , setShow] = useState(false)

    useEffect(() => {

    } ,[show])

    const handdleEvent = (e) => {
        setShow(!show)
    }

    return(

         <>
             <li >
                 <p className="question" onClick={(e) => handdleEvent(e)}>{question}</p>
                   <p className="answer" style={{display: `${show ? "block" : "none"}`}}>{response}</p>
             </li>
         </>


    )
}