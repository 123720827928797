import Reat from "react"
import Header from "../components/Sections/Header";
import React from "react";


export function UseCases(props) {
    const questionsAndResponse = [
        {title:"Web Scraping & API" , questionsAndResponses: [{ quiz : "How scalable is this service?" , response : "Scrapestack is a REST API service built on top of  apilayer cloud infrastructure, making it highly scalable and capable   of handling anything from thousands of API requests per month all the way to millions per day. Powered by a system that scales up and down when needed, the API provides the highest possible level of speed for any API request at any stage."}] }
    ]
    return(
        <>
            <div id="content" className="site-content">
                <div id="primary" className="content-area">

                    <main id="main" className="site-main">
                        <Header
                            title={"Use case"}
                            description={""}
                            h={200}
                        />

                        <section className="api_documentation mt-3 mb-5" >

                            <div className="container mt-2">
                                <div className="row align-items-start">

                                    <div className="col-lg-4 m-15px-tb blog-aside">


                                        <div className="widget widget-latest-post">
                                            <div className="widget-title">
                                                <h3>Details </h3>
                                            </div>
                                            <div className="widget-body">
                                                <div className="latest-post-aside media">
                                                    <div className="lpa-left media-body">
                                                        <div className="lpa-title">
                                                            <h5><a href="#">Prevent 75% of visitors from google analytics</a></h5>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="latest-post-aside media">
                                                    <div className="lpa-left media-body">
                                                        <div className="lpa-title">
                                                            <h5><a href="#">Prevent 75% of visitors from google analytics</a></h5>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className="widget widget-tags">
                                            <div className="widget-title">
                                                <h3>Topic </h3>
                                            </div>
                                            <div className="widget-body">
                                                <div className="nav tag-cloud">
                                                    <a href="#">Design</a>
                                                    <a href="#">Development</a>
                                                    <a href="#">Travel</a>
                                                    <a href="#">Web Design</a>
                                                    <a href="#">Marketing</a>
                                                    <a href="#">Research</a>
                                                    <a href="#">Managment</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 m-15px-tb">
                                        <article className="article">

                                            <div className="article-content">
                                                <p>Aenean eleifend ante maecenas pulvinar montes lorem et pede dis dolor pretium donec
                                                    dictum. Vici consequat justo enim. Venenatis eget adipiscing luctus lorem.
                                                    Adipiscing veni amet luctus enim sem libero tellus viverra venenatis aliquam.
                                                    Commodo natoque quam pulvinar elit.</p>
                                                <p>Eget aenean tellus venenatis. Donec odio tempus. Felis arcu pretium metus nullam quam
                                                    aenean sociis quis sem neque vici libero. Venenatis nullam fringilla pretium magnis
                                                    aliquam nunc vulputate integer augue ultricies cras. Eget viverra feugiat cras ut.
                                                    Sit natoque montes tempus ligula eget vitae pede rhoncus maecenas consectetuer
                                                    commodo condimentum aenean.</p>
                                                <h4>What are my payment options?</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                <blockquote>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                                                    <p className="blockquote-footer">Someone famous in <cite title="Source Title">Dick
                                                        Grayson</cite></p>
                                                </blockquote>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                                    deserunt mollit anim id est laborum.</p>
                                            </div>

                                        </article>

                                    </div>
                                </div>
                            </div>

                        </section>

                    </main>
                </div>
            </div>
        </>
    );
}