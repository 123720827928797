/***

 File to manage all fonction of projet


 **/
import AppConfig from "../assets/AppConfig";


export const getRoot = ( )  => {
    return AppConfig.root ;
}