import Reat from "react"
import Header from "../components/Sections/Header";
import React from "react";
import Blog from "../components/Sections/Blog";
import {OurModules} from "../components/Sections/OurModules";
import Pricings from "../components/Sections/Pricings";
import WhyUs from "../components/Sections/WhyUs";
import UseCase from "../components/Sections/UseCase";


export function Home(props) {

    return(
      <>
          <div id="content" className="site-content">
              <div id="primary" className="content-area">

                  <main id="main" class="site-main">
                          <Header/>
                          <section>
                           <OurModules/>
                           <Blog/>
                          <UseCase/>
                          <Pricings/>
                          <WhyUs/>

                      </section>
                  </main>
              </div>
          </div>
      </>
    );
}