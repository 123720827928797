import React , {useState} from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";

import { Routes, Route } from "react-router-dom"
import { BrowserRouter } from "react-router-dom";
 import { routes} from "./route/_routeServices";
import TopNavbar from "./components/Nav/TopNavbar";
import Footer from "./components/Sections/Footer";
import {Faq} from "./screens/Faq";
import {TermeCondition} from "./screens/TermeCondition";
import {PrivacyPolicy} from "./screens/PrivacyPolicy";
import {Login} from "./screens/Login";
import {Register} from "./screens/Register";
import {ChoosePlan} from "./screens/ChoosePlan";
import {Modules} from "./screens/Modules";
import DetailBlogs from "./screens/DetailBlogs";
import {UseCases} from "./screens/UseCases";
import {NewCompte} from "./screens/NewCompte";
import { useTranslation } from 'react-i18next';
export default function App() {

  const [selectedLang, setSelectedLang] = useState('en');
  
  return (


    <>
        <TopNavbar />
            <Routes>
                <Route path={"/"} element={ <Landing/> } />
                <Route path={"/faq"} element={ <Faq/> } />
                <Route path={"/hdc-terms-condition"} element={ <TermeCondition/> } />
                <Route path={"/hdc-privacy-policy"} element={ <PrivacyPolicy/> } />
                <Route path={"/login"} element={ <Login/> } />
                <Route path={"/signup/register/:plan"} element={ <Register/> } />
                <Route path={"/new-account"} element={ <ChoosePlan/> } />
                <Route path={"/modules/:id"} element={ <Modules/> } />
                <Route path={"/blogs/:id"} element={ <DetailBlogs/> } />
                <Route path={"/pricing/:id"} element={ <NewCompte/> } />
                <Route path={"/usecase/:id"} element={ <UseCases/> } />
            </Routes>
        <Footer/>
    </>
  );
}

