import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";
import {getRoot} from "../../common/helpers";

export default function Blog() {
    return (

        <Wrapper id="usecase">

            <div  style={{padding:"30px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 hdc--title">
                            <div className="hdc-vertical-spacer" style={{height: '20px'}}></div>

                            <div className="hdc-title ">
                                <div className="hdc-title--icon">
                                    <i className="hdc-icon hdc-icon-momo-transfer text-c-theme"></i></div>
                                <h4 className="hdc-title--heading">Use case</h4>

                                <button type="default"
                                        className="hdc-fab-btn--mini  hdc-fab-btn--outlined-yellow"
                                >
                                    <i className="mdi mdi-chevron-right"></i>
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="hdc-vertical-spacer" style={{height: '20px'}}></div>

                    <div className="row">
                        <a className="col-lg-3 article-card" data-cat="" href={getRoot()+"usecase/1"}>
                            <div className="hdc-card hdc-card--shadow hdc-card--image hdc-card--filter  hdc-card--cursor" data-year=""
                                 data-cat=""
                            >
                                <div className="hdc-card__img-container" ></div>

                                <div className="hdc-card__content">
                                    <p className="hdc-p">Linkedin</p>
                                </div>
                            </div>

                        </a>

                    </div>


                </div>
            </div>



        </Wrapper>
    );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;