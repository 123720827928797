import React from "react"
import CircleImage from "./../../assets/svg/feature_circle.svg"
import {Link} from "react-scroll";

export default function () {

    return(
        <>
            <section className="mtn-snippet--help no-padding" id={'whyus'}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 hdc--title">
                            <div className="hdc-vertical-spacer" style={{height: '20px'}}></div>

                            <div className="hdc-title ">
                                <div className="hdc-title--icon">
                                    <i className="hdc-icon hdc-icon-games text-c-theme"></i></div>
                                <h4 className="hdc-title--heading">Why us ?</h4>

                                <button type="default"
                                        className="hdc-fab-btn--mini  hdc-fab-btn--outlined-yellow"
                                >
                                    <i className="mdi mdi-chevron-right"></i>
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6 no-padding mtn-snippet--cta mtn-bg--white">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <img draggable="false" alt="Scraping API Features" src={CircleImage}/>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="content_box">

                                        <h3 className={"themecolor"}>Web Scraping, Simplified</h3>

                                        <p>Scraping the web can be a tough job if you handle things like IP blocks,
                                            CAPTCHAs and browsers yourself. With our API, you can start scraping content
                                            in under 5 minutes. </p>
                                        <br/><span className="why_choose themecolor">Why choose HDC ?</span>
                                        <p>
                                            <li>Never worry about IP blocks or proxy-related issues</li>
                                            <li>Supporting datacenter and residential proxies</li>
                                            <li>You'll be up and running with just a few lines of code</li>
                                        </p>
                                        <button className={"hdc-btn  hdc-btn--text-right-icon "} style={{background: "#e97d69"}}>
                                            <Link activeClass="active" style={{ padding: "10px 15px" }} to="usecase" spy={true} smooth={true} offset={-80}>
                                                Use case
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </section>

        </>
    );
}