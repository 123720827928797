import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";
import {getRoot} from "../../common/helpers";

export default function Blog() {
  return (

    <Wrapper id="blog">

      <div className="greyBg" style={{padding:"50px"}}>
        <div className="container">
          <div className="row">
            <div className="col-12 hdc--title">
              <div className="hdc-vertical-spacer" style={{height: '20px'}}></div>

              <div className="hdc-title ">
                <div className="hdc-title--icon">
                  <i className="hdc-icon hdc-icon-data-management text-c-theme"></i></div>
                <h4 className="hdc-title--heading">Blogs</h4>

                <button type="default"
                        className="hdc-fab-btn--mini  hdc-fab-btn--outlined-yellow"
                >
                  <i className="mdi mdi-chevron-right"></i>
                </button>

              </div>

            </div>
          </div>
        </div>
        <div className="container">
          <div className="hdc-vertical-spacer" style={{height: '20px'}}></div>

          <div className="row">
            <div className="col-lg-3 article-card" data-cat="">
               <div className="hdc-card hdc-card--shadow hdc-card--image hdc-card--filter  hdc-card--cursor" data-year=""
                   data-cat=""
                    >
                    <div className="hdc-card__img-container" ></div>

                    <div className="hdc-card__content">
                      <div className="hdc-tag hdc-tag--white"></div>
                      <p className="hdc-p">Getting Started</p>
                      <a className="hdc-btn hdc-btn--text-right-icon hdc-btn--outlined" href={getRoot() + "blogs/1"}>
                        Read more <i className="mdi mdi-chevron-right white"></i>
                      </a>

                    </div>
                </div>

            </div>

          </div>


        </div>
      </div>



    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;