import Reat from "react"
import Header from "../components/Sections/Header";
import React from "react";
import {OurModules} from "../components/Sections/OurModules";
import Blog from "../components/Sections/Blog";
import Pricings from "../components/Sections/Pricings";
import WhyUs from "../components/Sections/WhyUs";



export function Modules(props) {

    return(
        <>
            <div id="content" className="site-content">
                <div id="primary" className="content-area">

                    <main id="main" className="site-main">
                        <Header
                            title={"3-Step Quickstart Guide"}
                            description={">Can't wait to get started? Check out our quickstart guide to start making scraper API requests right away."}
                            h={200}
                        />
                        <section className="api_documentation mt-3 mb-5" >

                            <div className="container">

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="menu">

                                            <ul>
                                                <li className="doc_heading specs">Getting Started</li>
                                                <li><a href="#access_keys">API Access Key</a></li>
                                            </ul>

                                            <ul>
                                                <li className="doc_heading features">API Features</li>
                                                <li><a href="#basic_request">Basic Request</a></li>
                                            </ul>

                                            <ul>
                                                <li className="doc_heading examples">Code Examples</li>
                                                <li><a href="#php">PHP</a></li>
                                            </ul>

                                            <ul className="github">
                                                <li><a target="_blank" href="https://github.com/apilayer/scrapestack">scrapestack on GitHub</a></li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="content">

                                            <div className="topic">

                                                <h2>API Documentation</h2>

                                                <p>The scrapestack API was built to offer a simple REST API interface for scraping web pages at scale without having to programatically deal with geolocations, IP blocks or CAPTCHAs. The API supports a series of features essential to web scraping, such as JavaScript rendering, custom HTTP headers, various geo-targets, POST/PUT requests and an option to use premium residential proxies instead of datacenter proxies.</p>

                                                <p>In this documentation you will find detailed usage guides and code examples in different programming languages that will help you get up and running in the shortest time possible. If the articles below leave any questions unanswered, please feel free to contact our technical support team. </p>

                                            </div>

                                            <div id="access_keys" className="topic">

                                                <h2>Getting Started</h2>

                                                <h3>API Access Key & Authentication</h3>

                                                <p>After creating a scrapestack account, the account dashboard will reveal the unique API access key you can use to authenticate with the API. To do so, simply attach the <code>access_key</code> parameter to the API's base URL and set it to your API access key.</p>

                                                <pre className="prettyprint white"><a target="_blank" href="product.html" className="execute signup">Sign Up to Run API Request</a>https://api.scrapestack.com/scrape
                                            <span className="orange">? access_key = YOUR_ACCESS_KEY</span>
                                        </pre><br/>

                                                <p className="alert warning"><strong>Secure your key:</strong> To prevent unauthorized access to your scrapestack account, please make sure to store your API access key in a secure location and never include it in any public scripts or files.</p>

                                            </div>

                                            <div id="https" className="topic">

                                                <h3>256-bit HTTPS Encryption <span>Available on: Basic Plan and higher</span></h3>

                                                <p>Customers subscribed to the Basic Plan or higher may connect to the scrapestack API using industry-standard 256-bit HTTPS (SSL) encryption by appending an <code>s</code> to the HTTP protocol. Please find an illustration below.</p>

                                                <p className="subheading"><strong>Example API Request: </strong></p>

                                                <pre className="prettyprint white">
                                        <span className="orange">https</span><span>://api.scrapestack.com/scrape</span>
                                    </pre>
                                                <br/>

                                            </div>



                                            <div id="js_rendering" className="topic">

                                                <h3>JavaScript Rendering <span>Available on: Basic Plan and higher</span></h3>

                                                <p>Some web pages render essential page elements using JavaScript, which means that some content is not present (and therefore not scrapable) with the initial page load. With the <code>render_js</code> parameter enabled, the scrapestack API is capable of accessing the target web using a headless browser (Google Chrome) and allow JavaScript page elements to render before delivering the final scraping result. </p>

                                                <p>To enable JavaScript rendering, simply append the <code>render_js</code> HTTP GET parameter to your API request URL and set it to <code>1</code>. By default, this parameter is set to <code>0</code> (off).</p>

                                                <p className="subheading"><strong>Example API Request:</strong></p>


                                                <p className="subheading"><strong>Example API Response:</strong></p>

                                                <p className="alert info">To see an API response, please click the "Run API Request" button above or <a target="_blank" title="Sign up for API access" href="product.html">sign up</a> for an API access key.</p>

                                            </div>

                                            <div id="http_headers" className="topic">

                                                <h3>HTTP Headers <span>Available on: All plans</span></h3>

                                                <p>The scrapestack API will accept HTTP headers and pass them through to the target web page and the final API response if the <code>keep_headers</code> HTTP GET parameter is set to <code>1</code>. By default, this parameter is set to <code>0</code> (off).</p>

                                                <p>Below you will find an example API request (Bash using the "curl" command) that contains an HTTP X-Header. If this request is executed, the specified header will be sent to the target web page and returned in the final API response.</p>

                                                <p className="subheading"><strong>Example Bash (curl) Request with HTTP header:</strong></p>

                                                <pre className="prettyprint white">curl --header "<span className="orange">X-AnyHeader: Test</span>" \
"https://api.scrapestack.com/scrape?access_key=YOUR_ACCESS_KEY&url=https://apple.com"
</pre><br/>

                                                <p className="subheading"><strong>Unsupported HTTP Headers:</strong> Although most HTTP headers are supported by the API, there are some that cannot be processed. Please find a list of unsupported HTTP headers below:</p>

                                                <ul>
                                                    <li><code>content-encoding</code></li>
                                                    <li><code>content-length</code></li>
                                                </ul>

                                            </div>


                                            <p className="subheading"><strong>Example API Request:</strong></p>

                                            <pre className="prettyprint white"><a target="_blank" href="product.html" className="execute signup">Sign Up to Run API Request</a>https://api.scrapestack.com/<span className="orange">scrape</span>
    ? access_key = YOUR_ACCESS_KEY
    & url = https://apple.com
    <span className="orange">& proxy_location = au</span>
</pre><br/>

                                            <p className="subheading"><strong>Example API Response:</strong></p>

                                            <p className="alert info">To see an API response, please click the "Run API Request" button above or <a target="_blank" title="Sign up for API access" href="product.html">sign up</a> for an API access key.</p>

                                            <p className="subheading"><strong>Standard Proxies - Supported Countries:</strong></p>

                                            <p>For standard (datacenter) proxies, the API currently supports a total of 77 global geolocations. You can download a full list of supported countries and 2-letter country codes using the following link: <a download href="locations-standard-proxy.csv">locations-standard-proxy.csv</a></p>

                                            <p className="subheading"><strong>Premium Proxies - Supported Countries:</strong></p>

                                            <p>For premium (residential) proxies, the API currently supports a total of 38 global geolocations. You can download a full list of supported countries and 2-letter country codes using the following link: <a download href="locations-premium-proxy.csv">locations-premium-proxy.csv</a></p>

                                        </div>
                                    </div>
                                </div>



                              </div>

                        </section>

                    </main>
                </div>
            </div>
        </>
    );
}